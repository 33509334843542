export const TRADING_PLANS = [
	'None',
	'Forex-3000',
	'Gold-7600',
	'Cryptos-21199',
	'Synthetic Indices-50730',
];
export type TradingPlan =
	| 'None'
	| 'Forex-3000'
	| 'Gold-7600'
	| 'Cryptos-21199'
	| 'Synthetic Indices-50730';

export interface PlanRequest {
	id: string;
	user_id: string;
	plan: Omit<TradingPlan, 'None'>;
}

export interface User {
	phone: string;
	user_id: string;
	email: string;
	email_verified: boolean;
	firstname: string;
	lastname: string;
	avatar: string | null;
	referral_code: string | null;
	city: string | null;
	state: string | null;
	country: string | null;
	address: string | null;
	gender: string;
	date_of_birth: string | null;
	balance: number;
	demo_balance: number;
	trading_plan: TradingPlan;
	plan_request?: PlanRequest | null;
}

export interface UserProfileInput {
	phone: string;
	firstname: string;
	lastname: string;
	city: string | null;
	state: string | null;
	country: string | null;
	address: string | null;
	gender: string;
	avatar?: File;
}

export interface ChangePasswordInput {
	current: string;
	password: string;
	confirmPassword: string;
}
